<template>
    <div class="p-grid p-jc-center  no_margin" >
        <div class="p-col-9 p-md-9 p-lg-9 p-grid p-nogutter">
            <div class="p-col-12 text-left">
                <Button class="p-button-outlined p-button-info" @click="updateSettingLinkClick" v-bind:disabled="isProcessing()" >{{$t('setting.otherSetting')}}</Button>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import store from "@/store"
import Button from 'primevue/button';
import http from '../mixins/api';
import Processing from '@/mixins/processing'
import Dropdown from 'primevue/dropdown';



export default {
    name: 'Setting',
    mixins: [Processing],
    components: {
        Dropdown,
        Button,
    },
    data: function() {
        return {
        }
    },
    async beforeRouteEnter(route, redirect, next) {
        const userInfo = store.getters["auth/getUserInfo"]
        if(userInfo && userInfo.language) {
            store.commit("settingStore/setLanguageByValue", userInfo.language)
        }
        next()
    },

    computed: {
        LanguageList: {
            get: function() {
                return this.$store.getters["settingStore/getLanguageList"]
            }
        },
        LanguageInfo: {
            get: function() {
                return this.$store.getters["settingStore/getActiveLanguage"]
            },
            set: function(lang) {
                this.$store.commit("settingStore/setLanguageByValue", lang.value)
            }
        },

    },

    methods: {
        updateSetting: async function() {
            const res = await this.$store.dispatch("settingStore/updateSetting", this.LanguageInfo.value)
            if(res.Success) {
                this.$toast.add({severity:'success', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            } else  {
                this.$toast.add({severity:'error', summary: res.Message, life: this.$constants.successHelperLife, group: this.$constants.defaultToastGroup});
            }

            
            await this.$store.dispatch('routeStore/getRouteList')
            this.$store.commit("settingStore/setLanguage", this)
            this.$store.dispatch("auth/setLanguageInUserInfo", this.LanguageInfo.value)
        },

        updateSettingLinkClick: async function() {
            const res = await http.post("/user/GetUpdateSettingLink")
            console.log(res)
            if(res.Success) {
                const url = res.redirect_url
                window.open(url, '_blank')
            }

        }
    }
}
</script>



